import React, { useEffect, useState } from "react";
import "./Race.css";
import Header from "../../components/Header/BalanceHeader";
import RaceStream from "./components/RaceStream/RaceStream";
import RaceDetails from "./components/RaceDetails/RaceDetails";
import RaceClosedBetting from "./components/RaceDetails/RaceClosedBetting/RaceClosedBetting";
import RaceBetTypes from "./components/RaceBetTypes/RaceBetTypes";
import RaceNumber from "./components/RaceNumber/RaceNumber";
import RaceTab from "./components/RaceTab/RaceTab";
import RaceRunners from "./components/RaceRunners/RaceRunners";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import { useMediaQuery } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import BetModal from "./components/BetModal/BetModal";
import PromoDisplay from "./components/PromoDisplay/PromoDisplay";
import useEventsStore from "../../store/useEventStore";
import useRunnersStore from "../../store/useRunnersStore";
import ComingSoon from "../../components/LoadingState/ComingSoon";
import { useNavigate } from "react-router-dom";
import { eventSource } from "../../lib/sse";

const RacePage = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const { activeEvent, arenaName, eventName, poolCodes, fetchActiveEvent } =
    useEventsStore();
  const { raceBettingStatus, fetchRunners, setRaceBettingStatus } = useRunnersStore();
  const [selectedBetType, setSelectedBetType] = useState(null);
  const [selectedRaceRange, setSelectedRaceRange] = useState(null);
  const [activeRace, setActiveRace] = useState(null);
  const [betInputs, setBetInputs] = useState({});
  const [activeButtons, setActiveButtons] = useState({});
  const [isFirstRaceDisabled, setIsFirstRaceDisabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchActiveEvent(navigate);
  }, [fetchActiveEvent, navigate]);

  useEffect(() => {
    if (poolCodes.length > 0 && !selectedBetType) {
      setSelectedBetType(poolCodes[0].code);
    }
  }, [poolCodes, selectedBetType]);

  useEffect(() => {
    setActiveButtons({});
  }, [selectedRaceRange]);

  useEffect(() => {
    if (activeRace) {
      const race = activeEvent.races.find((r) => r.id === activeRace.id);
      if (race) {
        fetchRunners(activeRace.id);
      }
    }
  }, [activeRace, activeEvent, fetchRunners]);

  useEffect(() => {
    if (selectedRaceRange) {
      const raceNumbers = selectedRaceRange.split(/[-&]/).map(Number);
      const firstRaceDetails = activeEvent.races.find(
        (r) => r.raceNumber === raceNumbers[0]
      );
      if (firstRaceDetails) {
        setIsFirstRaceDisabled(firstRaceDetails.betting_status === "closed");
      }
    }
  }, [selectedRaceRange, selectedBetType, activeEvent]);


  useEffect(() => {

    eventSource.addEventListener('race', (event) => {
      setRaceBettingStatus(event.data)
    })
  }, [setRaceBettingStatus]);

  if (!activeEvent) {
    return <ComingSoon />;
  }

  const handleBetTypeSelect = (betType) => {
    setSelectedBetType(betType);
    setBetInputs({});
    setSelectedRaceRange(null);
    setActiveRace(null);
  };

  const handleBetInput = (input, runnerId, isActive) => {
    setBetInputs((prevInputs) => {
      const updatedInputs = { ...prevInputs };
      const [race, runnerNumber] = input.split(": ");

      if (!updatedInputs[race]) {
        updatedInputs[race] = [];
      }

      if (isActive) {
        if (!updatedInputs[race].some((r) => r.runnerNumber === runnerNumber)) {
          updatedInputs[race].push({ runnerNumber, runnerId });
        }
      } else {
        updatedInputs[race] = updatedInputs[race].filter(
          (r) => r.runnerNumber !== runnerNumber
        );
      }

      return updatedInputs;
    });

    setActiveButtons((prev) => ({
      ...prev,
      [activeRace]: {
        ...prev[activeRace],
        [input]: isActive,
      },
    }));
  };

  return (
    <>
      {isMobile ? <Header /> : <TopNavigation />}
      <div className="race-page-container">
        <div className="race-page">
          <RaceStream />
          {raceBettingStatus === "closed" ? (
            <RaceClosedBetting />
          ) : (
            <RaceDetails arenaName={arenaName} eventName={eventName} />
          )}
          <PromoDisplay/>
          <RaceBetTypes
            poolCodes={poolCodes}
            onBetTypeSelect={handleBetTypeSelect}
            selectedBetType={selectedBetType}
          />
          {selectedBetType && (
            <RaceNumber
              selectedBetType={selectedBetType}
              expectedRaces={activeEvent.raceCount}
              onRaceRangeSelect={setSelectedRaceRange}
            />
          )}
          <RaceTab
            selectedRaceRange={selectedRaceRange}
            onRaceSelect={setActiveRace}
          />
          <RaceRunners
            currentRace={activeRace}
            selectedBetType={selectedBetType}
            onBetInput={handleBetInput}
            activeButtons={activeButtons[activeRace] || {}}
            isFirstRaceDisabled={isFirstRaceDisabled}
            selectedRaceRange={selectedRaceRange}
          />
          <BetModal
            selectedBetType={selectedBetType}
            betInputs={betInputs}
            setBetInputs={setBetInputs}
            activeRace={activeRace}
            selectedRaceRange={selectedRaceRange}
            setActiveButtons={setActiveButtons}
          />
        </div>
      </div>
      {isMobile ? <BottomNavigation /> : <Footer />}
    </>
  );
};

export default RacePage;
