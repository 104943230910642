import React, { useState } from 'react';

const BetButton = ({ label, isActive, onClick, disabled, betType }) => {
  const [touchStart, setTouchStart] = useState(null);

  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    setTouchStart({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchEnd = (event) => {
    if (!touchStart) return;

    const touch = event.changedTouches[0];
    const deltaX = Math.abs(touch.clientX - touchStart.x);
    const deltaY = Math.abs(touch.clientY - touchStart.y);

    if (deltaX < 5 && deltaY < 5) {
      handleClick(event);
    }

    setTouchStart(null);
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (!disabled) {
      onClick(!isActive);
    }
  };

  // Helper function to get styles based on bet type
  const getButtonSizeStyles = (type) => {
    switch (type) {
      case 'PENTA':
        return { width: '29px', height: '29px', marginLeft: '5px', borderRadius: '50%'};
      case 'QRT':
        return { width: '31px', height: '31px', marginLeft: '5px', borderRadius: '50%' };
      case 'TRI':
        return { width: '36px', height: '36px', marginLeft: '5px', borderRadius: '50%' };
      default:
        return { width: '85px', height: '40px', marginLeft: '10px', borderRadius: '25px' };
    }
  };

  const sizeStyles = getButtonSizeStyles(betType);

  // Define button styles
  const buttonStyle = {
    ...sizeStyles,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    border: '2px solid #A4C639',
    backgroundColor: disabled ? '#808080' : (isActive ? '#c9f62e' : 'transparent'),
    color: isActive ? '#242424' : '#D7D7D7',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    opacity: disabled ? 0.5 : 1,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '12px', // Adjusted for smaller buttons
    fontFamily: 'Roboto Flex, sans-serif',
  };

  return (
    <button
      onClick={handleClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      className={`bet-button ${isActive ? 'active' : ''}`}
      disabled={disabled}
      style={buttonStyle}
    >
      {label}
    </button>
  );
};

export default BetButton;
