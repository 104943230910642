import { create } from "zustand";
import { useAuthStore } from "./useAuthStore";

const apiURL = process.env.REACT_APP_API_URL;

export const useWalletStore = create((set, get) => ({
  lastDepositTime: null,

  cashIn: async (amount, bank, referenceNumber, accountNumber, remarks) => {
    const { token } = useAuthStore.getState();
    const { lastDepositTime } = get();

    if (
      process.env.REACT_APP_ENVIRONMENT === "production" &&
      lastDepositTime &&
      Date.now() - lastDepositTime < 6 * 60 * 1000
    ) {
      throw new Error("Please wait for 5 mins to request again");
    }

    const requestBody = {
      amount,
      metadata: {
        bank,
        referenceNumber,
        accountNumber,
        remarks,
      },
    };

    console.log("Request Body:", requestBody);

    try {
      const response = await fetch(`${apiURL}/wallet/cash-in`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to cash in");
      }

      const data = await response.json();
      console.log("Cash-in data:", data);
      set({ lastDepositTime: Date.now() });
      return data;
    } catch (error) {
      console.error("Cash-in error:", error.message);
      throw error;
    }
  },

  cashOut: async (amount, bank, accountNumber, accountName, remarks) => {
    const { token } = useAuthStore.getState();
    try {
      const response = await fetch(`${apiURL}/wallet/cash-out`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          amount,
          metadata: {
            bank,
            accountNumber,
            accountName,
            remarks,
          },
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to cash out");
      }

      const data = await response.json();
      console.log("Cash-out data:", data);
      return data;
    } catch (error) {
      console.error("Cash-out error:", error.message);
      throw error;
    }
  },

  transactions: [],

  fetchTransactions: async () => {
    const { token } = useAuthStore.getState();
    try {
      const response = await fetch(`${apiURL}/transaction/me`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch transactions");
      }

      const data = await response.json();

      // Sort transactions by date in descending order
      const sortedTransactions = data.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );

      set({ transactions: sortedTransactions });
    } catch (error) {
      console.error("Transaction fetch error:", error.message);
    }
  },
}));
