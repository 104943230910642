import React, { useState, useEffect } from "react";
import "./RaceBetTypes.css";

const RaceBetTypes = ({ poolCodes, onBetTypeSelect, selectedBetType }) => {
  const [activeCode, setActiveCode] = useState(selectedBetType);
  const [hoveredButton, setHoveredButton] = useState(null); // To track hovered button

  useEffect(() => {
    setActiveCode(selectedBetType);
  }, [selectedBetType]);

  const handleButtonClick = (code) => {
    setActiveCode(code);
    onBetTypeSelect(code); // Notify parent of selected bet type
  };

  const handleTouchStart = (index) => {
    setHoveredButton(index);
  };

  const handleTouchMove = () => {
    setHoveredButton(null); // Remove hover effect when scrolling
  };

  const handleTouchEnd = () => {
    setHoveredButton(null); // Clear hover effect after touch ends
  };

  return (
    <div
      className="race-bet-types"
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {poolCodes.map((pool, index) => (
        <button
          key={pool.id}
          className={`bet-type-button ${
            activeCode === pool.code ? "active" : ""
          } ${hoveredButton === index ? "hovered" : ""}`}
          onClick={() => handleButtonClick(pool.code)}
          onTouchStart={() => handleTouchStart(index)}
        >
          {pool.code}
        </button>
      ))}
    </div>
  );
};

export default RaceBetTypes;
