import React, { useState, useEffect } from "react";
import "./RaceTab.css";

const RaceTab = ({ selectedRaceRange, onRaceSelect }) => {
  const [activeRace, setActiveRace] = useState(null);
  const [hoveredButton, setHoveredButton] = useState(null); // Track hovered button

  useEffect(() => {
    if (selectedRaceRange) {
      const firstRaceNumber = getFirstRaceNumber(selectedRaceRange);
      setActiveRace(firstRaceNumber);
      onRaceSelect(firstRaceNumber); // Notify parent of the default selected race
    }
  }, [selectedRaceRange, onRaceSelect]);

  const getFirstRaceNumber = (range) => {
    if (range.includes("&")) {
      return parseInt(range.split("&")[0], 10);
    } else if (!range.includes("-")) {
      return parseInt(range, 10);
    } else {
      return parseInt(range.split(/[-&]/)[0], 10);
    }
  };

  const handleRaceSelect = (raceNumber) => {
    setActiveRace(raceNumber);
    onRaceSelect(raceNumber); // Notify parent of the selected race
  };

  const handleTouchStart = (index) => {
    setHoveredButton(index);
  };

  const handleTouchMove = () => {
    setHoveredButton(null); // Remove hover effect when scrolling
  };

  const handleTouchEnd = () => {
    setHoveredButton(null); // Clear hover effect after touch ends
  };

  const renderRaceButtons = () => {
    if (!selectedRaceRange) return null;

    const raceButtons = [];

    if (selectedRaceRange.includes("&")) {
      const [start, end] = selectedRaceRange.split("&").map(Number);

      raceButtons.push(
        <button
          key={start}
          className={`race-button ${
            activeRace === start ? "active" : ""
          } ${hoveredButton === start ? "hovered" : ""}`}
          onClick={() => handleRaceSelect(start)}
          onTouchStart={() => handleTouchStart(start)}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          Race {start}
        </button>
      );
      raceButtons.push(
        <button
          key={end}
          className={`race-button ${
            activeRace === end ? "active" : ""
          } ${hoveredButton === end ? "hovered" : ""}`}
          onClick={() => handleRaceSelect(end)}
          onTouchStart={() => handleTouchStart(end)}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          Race {end}
        </button>
      );
    } else if (!selectedRaceRange.includes("-")) {
      const raceNumber = parseInt(selectedRaceRange, 10);
      raceButtons.push(
        <button
          key={raceNumber}
          className={`race-button ${
            activeRace === raceNumber ? "active" : ""
          } ${hoveredButton === raceNumber ? "hovered" : ""}`}
          onClick={() => handleRaceSelect(raceNumber)}
          onTouchStart={() => handleTouchStart(raceNumber)}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          Race {raceNumber}
        </button>
      );
    } else {
      const [start, end] = selectedRaceRange.split(/[-&]/).map(Number);
      for (let i = start; i <= end; i++) {
        raceButtons.push(
          <button
            key={i}
            className={`race-button ${
              activeRace === i ? "active" : ""
            } ${hoveredButton === i ? "hovered" : ""}`}
            onClick={() => handleRaceSelect(i)}
            onTouchStart={() => handleTouchStart(i)}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            Race {i}
          </button>
        );
      }
    }

    return raceButtons;
  };

  return (
    <div className="race-result-wrapper">
      <div className="race-tabs-container">
        {/* Render race-specific content */}
        <div className="race-content"></div>
      </div>

      {/* Display race buttons */}
      <div className="race-buttons-container">{renderRaceButtons()}</div>
    </div>
  );
};

export default RaceTab;
