import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css'; // External CSS file

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="footer-container">
      <div className="footer-links">
        <div onClick={() => navigate('/')} className="footer-link">Home</div>
        <div onClick={() => navigate('/account/about')} className="footer-link">About Us</div>
        <div onClick={() => navigate('/account/terms-condition')} className="footer-link">Terms & Conditions</div>
        <div onClick={() => navigate('/account/private-policy')} className="footer-link">Privacy Policy</div>
      </div>
      <div className="footer-copyright">
        <p>Copyright 2025 www.KareraKing.com | All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
