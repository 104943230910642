import React, { useEffect, useState } from "react";
import { useAuthStore } from "./store/useAuthStore";
import AppRoutes from "./routes";
import LoadingPage from "./components/LoadingState/Load";
import { eventSource } from "./lib/sse";

const App = () => {
  const loadAuthState = useAuthStore((state) => state.loadAuthState);
  const logout = useAuthStore((state) => state.logout);
  const initialLoading = useAuthStore((state) => state.initialLoading);
  const [isSSEConnected, setIsSSEConnected] = useState(false);

  useEffect(() => {
    const sseCheck = setInterval(function() {
      if (eventSource.readyState === 1 || eventSource.readyState === 2) {
          clearInterval(sseCheck);
          setIsSSEConnected(true);
      }
    }, 500);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    setIsSSEConnected(eventSource.readyState === 1);

    if (token || refreshToken) {
      loadAuthState();
    } else {
      useAuthStore.setState({ initialLoading: false });
    }

    const handleStorageChange = (event) => {
      if (event.key === "token" || event.key === "refreshToken") {
        loadAuthState();
      } else if (event.key === "logout") {
        logout();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [loadAuthState, logout]);

  if (initialLoading || !isSSEConnected) {
    return <LoadingPage />;
  }

  return <AppRoutes />;
};

export default App;
