import { create } from "zustand";
import axios from "axios";
import { useAuthStore } from "./useAuthStore";

const apiURL = process.env.REACT_APP_API_URL;

const useEventsStore = create((set) => ({
  events: [],
  latestEvent: null,
  activeEvent: null,
  races: [],
  arenaName: '',
  eventName: '',
  poolCodes: [],
  videoLink: '',
  pick4set: [],
  pick5set: [],
  pick6set: [],
  wtasset: [],
  qrtset:[],
  triset:[],
  pentaset:[],
  loading: false,

  fetchActiveEvent: async (navigate) => {
    const { token } = useAuthStore.getState();
    set({ loading: true });
    try {
      const response = await axios.get(`${apiURL}/event/status/active`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const activeEvents = response.data;
        if (activeEvents.length > 0) {
          const activeEvent = activeEvents[0];
          set({ 
            activeEvent, 
            races: activeEvent.races, 
            eventName: activeEvent.name,
            videoLink: activeEvent.metadata.videoLink,
            triset: activeEvent.metadata.triset,
            qrtset: activeEvent.metadata.qrtset,
            pentaset: activeEvent.metadata.pentaset,
            pick4set: activeEvent.metadata.pick4set,
            pick5set: activeEvent.metadata.pick5set,
            pick6set: activeEvent.metadata.pick6set,
            wtasset: activeEvent.metadata.wtasset,
            loading: false
          });
          await useEventsStore.getState().fetchArenaName(activeEvent.arenaId);
          await useEventsStore.getState().fetchPoolCodes(activeEvent.metadata.enabledPoolIds);
        } else {
          console.error("No active event found");
          set({ loading: false });
        }
      } else if (response.status === 401) {
        console.error("Unauthorized access, redirecting to login");
        navigate("/login");
      } else {
        console.error("Failed to fetch active event: ", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching active event:", error.message);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    } finally {
      set({ loading: false });
    }
  },

  fetchArenaName: async (arenaId) => {
    const { token } = useAuthStore.getState();
    try {
      const response = await axios.get(`${apiURL}/arena/${arenaId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        set({ arenaName: response.data.name });
      } else {
        console.error("Failed to fetch arena name: ", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching arena name:", error.message);
    }
  },

  fetchPoolCodes: async (enabledPoolIds) => {
    const { token } = useAuthStore.getState();
    try {
      const response = await axios.get(`${apiURL}/pool`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const pools = response.data;
        const matchedPools = pools
          .filter(pool => enabledPoolIds.includes(pool.id))
          .map(pool => ({ id: pool.id, code: pool.code }));
        set({ poolCodes: matchedPools });
      } else {
        console.error("Failed to fetch pools: ", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching pools:", error.message);
    }
  },

}));

export default useEventsStore;
