import React from "react";
import "./PromoDisplay.css";

const PromoDisplay = () => {
  return (
    <div className="dynamic-race-display">
      <div className="animated-text">
        Mas malaking PATAMA!{" "}
        <span className="underline">Jan 22 - Feb 22</span>{" "}
        <span className="green-text">+17%</span>{" "}
        <span className="underline">FC, DD, XD</span>
        {"      "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {/* Add gap here */}
        Ano pa hinihintay mo? <span className="highlight">BET NA!</span>
      </div>
    </div>
  );
};

export default PromoDisplay;
