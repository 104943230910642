// src/pages/LoginPage.jsx
import React from "react";
import Banner from "../../components/BannerSection/Banner.jsx";
import LoginSection from "../../components/LoginSection/LoginSection.jsx";
import "../../pages/Login/LoginPage.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function Login() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <div className="login-page">
        <div className="banner-half">
          <Banner />
        </div>

        <div className="login-half">
          <LoginSection />
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default Login;
