export const getTotalCombination = (positions) => {
  console.log("Calculating Total Combinations for positions:", positions);

  // Recursive function to calculate valid combinations without duplicates across positions
  const calculateCombinations = (pos, used = new Set()) => {
    if (pos.length === 0) return 1; // Base case: all positions have been filled

    const currentPosition = pos[0];
    let totalCombinations = 0;

    currentPosition.forEach((runner) => {
      const runnerNumber = runner.runnerNumber.match(/\d+/)[0]; // Normalize runner number by extracting only the numeric part
      if (!used.has(runnerNumber)) {
        const newUsed = new Set(used); // Clone the current set of used runners
        newUsed.add(runnerNumber); // Add the normalized runner number to the used set
        totalCombinations += calculateCombinations(pos.slice(1), newUsed); // Recurse for remaining positions
      }
    });

    console.log("Combinations for position:", pos, "is", totalCombinations); 
    return totalCombinations; // Return the total valid combinations for the current branch
  };

  // Preprocess positions to group runners with the same numeric part
  const normalizedPositions = positions.map((runners) => {
    const runnerGroups = new Map();
    runners.forEach((runner) => {
      const numericPart = runner.runnerNumber.match(/\d+/)[0]; // Extract numeric part
      if (!runnerGroups.has(numericPart)) {
        runnerGroups.set(numericPart, runner);
      }
    });
    return Array.from(runnerGroups.values()); // Convert the Map back to an array
  });

  console.log("Normalized Positions:", normalizedPositions);

  const total = calculateCombinations(normalizedPositions);
  console.log("Total Combinations Calculated:", total); // Log final result
  return total;
};


export const calculateTotalBet = (betInputs, betAmount, selectedBetType, multiplier = 1) => {
  console.log("Calculating Total Bet...");
  console.log("Inputs:", { betInputs, betAmount, selectedBetType, multiplier });
  let total = 0;
  const betPerCombination = parseFloat(betAmount) || 0;

  const getUniqueRunnersCount = (runners) => {
    const uniqueNumbers = new Set();
    runners.forEach((runner) => {
      const numericPart = runner.runnerNumber.match(/\d+/)[0];
      uniqueNumbers.add(numericPart);
    });
    return uniqueNumbers.size;
  };

  switch (selectedBetType) {
    case "FC":
      const firstPlaceBets = betInputs["1st Place"] || [];
      const secondPlaceBets = betInputs["2nd Place"] || [];
      const numWinning = getUniqueRunnersCount(firstPlaceBets);
      const numSecondPlace = getUniqueRunnersCount(secondPlaceBets);
      const uniqueFirstPlace = new Set(firstPlaceBets.map((runner) => runner.runnerNumber.match(/\d+/)[0]));
      const uniqueSecondPlace = new Set(secondPlaceBets.map((runner) => runner.runnerNumber.match(/\d+/)[0]));
      const identicalPredictions = [...uniqueFirstPlace].filter((num) => uniqueSecondPlace.has(num)).length;
      total += betPerCombination * ((numWinning * numSecondPlace) - identicalPredictions);
      break;

    case "TRI":
      const triPositions = [
        betInputs["1st Place"] || [],
        betInputs["2nd Place"] || [],
        betInputs["3rd Place"] || [],
      ];
      const triCombination = getTotalCombination(triPositions);
      total += betPerCombination * triCombination;
      break;

    case "QRT":
      const qrtPositions = [
        betInputs["1st Place"] || [],
        betInputs["2nd Place"] || [],
        betInputs["3rd Place"] || [],
        betInputs["4th Place"] || [],
      ];
      const qrtCombination = getTotalCombination(qrtPositions);
      total = 2 * qrtCombination * multiplier;
      break;

    case "PENTA":
      const pentaPositions = [
        betInputs["1st Place"] || [],
        betInputs["2nd Place"] || [],
        betInputs["3rd Place"] || [],
        betInputs["4th Place"] || [],
        betInputs["5th Place"] || [],
      ];
      const pentaCombination = getTotalCombination(pentaPositions);
      total = 2 * pentaCombination * multiplier;
      break;

    case "DD":
    case "XD":
      const races = Object.keys(betInputs);
      if (races.length >= 2) {
        const firstRaceBets = betInputs[races[0]] || [];
        const secondRaceBets = betInputs[races[1]] || [];
        const numFirstRace = getUniqueRunnersCount(firstRaceBets);
        const numSecondRace = getUniqueRunnersCount(secondRaceBets);
        total += betPerCombination * (numFirstRace * numSecondRace);
      }
      break;

    case "PICK4":
    case "PICK5":
    case "PICK6":
    case "WTA":
      const raceCounts = Object.values(betInputs).map((runners) => getUniqueRunnersCount(runners));
      total = 2 * raceCounts.reduce((acc, count) => acc * count, 1) * multiplier;
      break;

    case "DD+1":
      const [n1 = 0, n2 = 0, n3 = 0] = Object.values(betInputs).map((runners) => getUniqueRunnersCount(runners));
      total = betPerCombination * n1 * n2 * n3;
      break;

    case "W":
      const numBets = Object.values(betInputs).reduce((acc, runners) => acc + getUniqueRunnersCount(runners), 0);
      total += betPerCombination * numBets;
      break;

    default:
      console.log("Unsupported bet type:", selectedBetType);
      break;
  }
  console.log("Total Bet Calculated:", total);
  return total;
};

export const isConfirmButtonDisabled = (selectedRaceRange, selectedBetType, betInputs, betAmount) => {
  console.log("Checking if Confirm Button should be disabled...");
  console.log("Inputs:", { selectedRaceRange, selectedBetType, betInputs, betAmount });

  // Ensure a race range is selected
  if (!selectedRaceRange) {
    console.log("Confirm Button Disabled: No race range selected.");
    return true;
  }

  // Define bet types that do not require betAmount
  const betAmountNotRequired = ["TRI", "QRT", "PENTA", "PICK4", "PICK5", "PICK6", "WTA"];
  const isBetAmountRequired = !betAmountNotRequired.includes(selectedBetType);

  let isDisabled;
  switch (selectedBetType) {
    case "W":
      // Win bets require betAmount and at least one runner in the inputs
      isDisabled = isBetAmountRequired && (!betAmount || Object.values(betInputs).every((runners) => runners.length === 0));
      break;

    case "FC":
      // Forecast requires 1st and 2nd place inputs and betAmount
      isDisabled = isBetAmountRequired && (!betAmount || !betInputs["1st Place"]?.length || !betInputs["2nd Place"]?.length);
      break;

    case "TRI":
    case "QRT":
    case "PENTA":
      // TRI, QRT, PENTA do not require betAmount, but all required inputs must be provided
      const requiredPositions = ["1st Place", "2nd Place", "3rd Place"];
      if (selectedBetType === "QRT") requiredPositions.push("4th Place");
      if (selectedBetType === "PENTA") requiredPositions.push("4th Place", "5th Place");

      isDisabled = requiredPositions.some((place) => !betInputs[place]?.length);
      console.log(`DEBUG: Required positions for ${selectedBetType}:`, requiredPositions);
      break;

    case "DD":
    case "XD":
      // Daily Double and Cross Double require at least two races with inputs and betAmount
      isDisabled = isBetAmountRequired && (!betAmount || Object.keys(betInputs).length < 2 || Object.values(betInputs).some((runners) => runners.length === 0));
      break;

    case "DD+1":
      // DD+1 requires three races with inputs and betAmount
      isDisabled = isBetAmountRequired && (!betAmount || Object.keys(betInputs).length < 3 || Object.values(betInputs).some((runners) => runners.length === 0));
      break;

    case "PICK4":
    case "PICK5":
    case "PICK6":
    case "WTA":
      // PICK bets and WTA do not require betAmount, but inputs for each race in the range are required
      const raceNumbers = selectedRaceRange.split(/[-&]/).map(Number);
      isDisabled = raceNumbers.some((raceNumber) => !betInputs[`Race ${raceNumber}`]?.length);
      console.log("DEBUG: Race numbers parsed:", raceNumbers);
      break;

    default:
      console.log("Unhandled bet type:", selectedBetType);
      isDisabled = true;
      break;
  }

  console.log("Confirm Button Disabled Result:", isDisabled);
  return isDisabled;
};
