import { create } from "zustand";
import { useAuthStore } from "./useAuthStore";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

export const useBetStore = create((set) => ({
  placeBet: async (raceId, betData) => {
    const { token } = useAuthStore.getState();
    
    // Log the data being passed to the API
    console.log("API URL:", `${apiURL}/bet/${raceId}`);
    console.log("Request Headers:", {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    console.log("Request Data:", betData);

    try {
      const response = await axios.post(`${apiURL}/bet/${raceId}`, betData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response; // Return the response object
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Log the response body
        console.error("Response Body:", error.response.data);
        throw new Error("Race is closed, you can't place your bet.");
      } else {
        console.error("Error placing bet:", error.message);
        throw error; // Throw the error to be caught in BetModal
      }
    }
  },
}));
